import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  // Add small delay to remove ExpressionChangedAfterItHasBeenCheckedError
  isLoading$ = this.isLoadingSubject.asObservable().pipe(delay(100));

  // Manually add urls to ignore the loading state
  private ignoredUrls: string[] = [];

  // This method is responsible for displaying the loading indicator to indicate that some asynchronous
  // operation is in progress. When invoked, it increments the internal count of pending requests,
  // indicating that a new request has been initiated.
  //
  // An optional url param can be passed to check this request should be ignored from starting the loader.

  public showLoader(url = '') {
    if(this.isUrlIgnored(url)){
        return;
    }

    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
    }
    this.apiCount++;
  }

  // This method is responsible for hiding the loading indicator when all pending requests are completed.
  //  It decrements the internal count of pending requests, indicating that a request has been completed.
  public hideLoader() {
    this.apiCount--;
    if (this.apiCount <= 0) {
      this.apiCount = 0;
      this.isLoadingSubject.next(false);
    }
  }

  public hideLoaderManually() {
      this.apiCount = 0;
      this.isLoadingSubject.next(false);
  }

  private isUrlIgnored(url: string): boolean {
    return this.ignoredUrls.some(ignoredUrl => url.includes(ignoredUrl));
  }

}
