import { Injectable } from '@angular/core';
import { GlobalNavService, User } from 'ngx-global-nav';
import { AnalyticsService } from 'ngx-shared-services';

/**
 * A service to easily implement mixpanel tracking
 */
@Injectable({ providedIn: 'root' })
export class MixPanelService {
  EVENTS = {
    VIEW_COM: 'View COM',
    VIEW_OPEN: 'View Open Tab',
    VIEW_CLEARED: 'View Cleared Tab',
    VIEW_SCHEDULE: 'View Schedule Tab',
    VIEW_BASELINES: 'View Baselines Tab',
    SELECT_PROGRAM: 'Select Program',
    SELECT_REGISTRATION: 'Select Registration',
    SELECT_DATE_RANGE: 'Select Date Range',
    EDIT_TABLE: 'Edit Table',
    CANCEL_EDIT_TABLE: 'Cancel Edit Table',
    BULK_CLICK_ENTIRE_WEEK: 'Click Entire Week',
    BULK_CLICK_DAY: 'Click ',
    BULK_CLICK_CUSTOM_DATE: 'Click Custom Date Range',
    CLICK_BULK_APPLY: 'Apply Bulk',
    CLICK_CUSTOM_DATE: 'Custom Date Range'
  };
  APPLICATION_NAME = 'COM';

  isInit = false;
  user!: User;

  constructor(private analyticsService: AnalyticsService, private globalNavService: GlobalNavService) {
    this.globalNavService.userData$.subscribe((user) => {
      if (user && !this.isInit) {
        this.user = user;
        this.analyticsService.init(user.user_id, this.APPLICATION_NAME);
        this.analyticsService.setPage(this.APPLICATION_NAME);
        this.viewCOM();
        this.isInit = true;
      }
    });
  }

  viewCOM() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.VIEW_COM, { application: this.APPLICATION_NAME });
    }
  }

  selectProgram(program: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.SELECT_PROGRAM, { program });
    }
  }

  selectRegistration(registration: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.SELECT_REGISTRATION, { registration });
    }
  }

  selectDateRange(dateRange: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.SELECT_DATE_RANGE, { dateRange });
    }
  }

  editTable(table: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.EDIT_TABLE, { table });
    }
  }

  cancelEditTable(table: string) {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CANCEL_EDIT_TABLE, { table });
    }
  }

  clickBulkDay(day: string) {
    if (this.user) {
      if(day === 'Week') {
        this.analyticsService.pushEvent(this.EVENTS.BULK_CLICK_ENTIRE_WEEK, {});
      } else if(day === 'Custom') {
        this.analyticsService.pushEvent(this.EVENTS.CLICK_CUSTOM_DATE, {});
      } else {
        this.analyticsService.pushEvent(this.EVENTS.BULK_CLICK_DAY + day, {});
      }
    }
  }

  clickBulkApply() {
    if (this.user) {
      this.analyticsService.pushEvent(this.EVENTS.CLICK_BULK_APPLY, {});
    }
  }

  viewTab(tabName: string) {
    if (this.user) {
      switch(tabName) {
        case 'Open': {
          this.analyticsService.pushEvent(this.EVENTS.VIEW_OPEN, {});
          break;
        }
        case 'Cleared': {
          this.analyticsService.pushEvent(this.EVENTS.VIEW_CLEARED, {});
          break;
        };
        case 'Schedule': {
          this.analyticsService.pushEvent(this.EVENTS.VIEW_SCHEDULE, {});
          break;
        }
        case 'Baselines': {
          this.analyticsService.pushEvent(this.EVENTS.VIEW_BASELINES, {});
          break;
        }
        default: {
          this.analyticsService.pushEvent(this.EVENTS.VIEW_OPEN, {});
          break;
        }
      }
    }
  }
}
