import { Injectable } from '@angular/core';
import { OfferResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { exportFile } from 'common-utils/dist/csv-export';
import * as moment from 'moment';
import { NgxDropdownOption } from 'ngx-dropdown';
import { Prices } from 'ngx-interval-data-grid';
import { ExportReportOutput, TabOptionsEnum, TabOptionValue } from '../models';

interface ExportOffersModel {
  registrationId: string;
  registrationName: string;
  date: string;
  intervalStart: string;
  intervalEnd: string;
  offerValue?: string;
  optOut?: string;
  price?: Prices;
  capacity?: string;
  capacity_volume?: string;
}

@Injectable({ providedIn: 'root' })
export class ExportCSVService {

  protected headerTitlesForTabs = {
    default: Object.freeze({
      registrationId: 'COM.CSV.CSV_REG_ID',
      registrationName: 'COM.CSV.CSV_REG_NAME',
      date: 'COM.CSV.CSV_DATE_RANGE',
      intervalStart: 'COM.CSV.CSV_INTERVAL_START',
      intervalEnd: 'COM.CSV.CSV_INTERVAL_END',
      offerValue: 'COM.CSV.CSV_OFFER_VALUE',
      optOut: 'COM.CSV.CSV_OPT_OUT'
    }),
    clear: Object.freeze({
      registrationId: 'COM.CSV.CSV_REG_ID',
      registrationName: 'COM.CSV.CSV_REG_NAME',
      date: 'COM.CSV.CSV_DATE_RANGE',
      intervalStart: 'COM.CSV.CSV_INTERVAL_START',
      intervalEnd: 'COM.CSV.CSV_INTERVAL_END',
      capacity_volume: 'COM.CSV.CSV_CAPACITY_VOLUME',
      capacity_bonus: 'COM.CSV.CAPACITY_BONUS_PRICE',
      capacity: 'COM.CSV.CSV_CAPACITY_PRICE',
      energy: 'COM.CSV.CSV_ENERGY_PRICE'
    })
  };

  constructor(private translate: TranslateService) {}

  public exportCSV(
    dateRange: ExportReportOutput,
    timeZone: string | undefined,
    selectedRegistration: NgxDropdownOption,
    offer: OfferResponse[] | null | undefined,
    tabName: TabOptionValue
  ): void {
    if (!offer || !timeZone) return;

    const filterStart = moment.tz(dateRange.startMomentDate?.format('YYYY-MM-DD HH:mm:ss'), timeZone);
    const filterEnd = moment.tz(dateRange.endMomentDate?.format('YYYY-MM-DD HH:mm:ss'), timeZone);

    const filteredData: OfferResponse[] = offer.filter((elem: OfferResponse) => {
      const start = moment.tz(elem.offer_start_dttm_utc, timeZone);
      const end = moment.tz(elem.offer_end_dttm_utc, timeZone);

      const isWithinRange =
        end.isSameOrBefore(filterEnd) &&
        start.isSameOrAfter(filterStart);

      return isWithinRange;
    });

    const sortedData: OfferResponse[] = filteredData.sort((a, b) =>
      moment(a.offer_start_dttm_utc).diff(moment(b.offer_start_dttm_utc))
    );

    if (sortedData && selectedRegistration) {
      const dataForCSV: ExportOffersModel[] = sortedData.map((elem: OfferResponse) => {
        if (tabName === TabOptionsEnum.Cleared) {
          return {
            registrationId: selectedRegistration.id,
            registrationName: `"${selectedRegistration.value}"`,
            date: moment(elem.offer_start_dttm_utc).tz(timeZone).format('"ddd, DD MMMM YYYY"'),
            intervalStart: moment(elem.offer_start_dttm_utc).tz(timeZone).format('H:mm'),
            intervalEnd: moment(elem.offer_end_dttm_utc).tz(timeZone).format('H:mm'),
            capacity_volume: `${elem.cleared?.quantities?.['capacity']}`,
            capacity_bonus: `${elem.cleared?.prices?.['capacity_bonus']}`,
            capacity: `${elem.cleared?.prices?.['capacity']}`,
            energy: `${elem.cleared?.prices?.['energy']}`
          };
        } else {
          return {
            registrationId: selectedRegistration.id,
            registrationName: `"${selectedRegistration.value}"`,
            date: moment(elem.offer_start_dttm_utc).tz(timeZone).format('"ddd, DD MMMM YYYY"'),
            intervalStart: moment(elem.offer_start_dttm_utc).tz(timeZone).format('H:mm'),
            intervalEnd: moment(elem.offer_end_dttm_utc).tz(timeZone).format('H:mm'),
            offerValue: `${elem.offer_value['kW']}`,
            optOut: elem.offer_opt_out_state ? this.translate.instant('COM.CSV.CSV_YES') : this.translate.instant('COM.CSV.CSV_NO')
          };
        }
      });


      exportFile(this.buildDataForCSV(dataForCSV, tabName), this.buildUniqueFileName());
    }
  }

  private buildDataForCSV(dataForCSV: ExportOffersModel[], tabName: TabOptionValue): string {
    const tabKey = tabName === TabOptionsEnum.Cleared ? 'clear' : 'default';

    const headers: string[] = Object.keys(this.headerTitlesForTabs[tabKey]).map(
      (key) => this.translate.instant(this.headerTitlesForTabs[tabKey][key as keyof typeof this.headerTitlesForTabs[typeof tabKey]])
    );

    // Convert array of objects to CSV
    const csvArray: string[] = [
      headers.join(','), // Header row
      ...dataForCSV.map((obj) => Object.values(obj).join(',')) // Data rows
    ];

    return csvArray.join('\n');
  }

  private buildUniqueFileName(): string {
    const timestamp: number = Date.now();
    const randomId: string = Math.random().toString(36).substring(7);
    const fileName = `csv_export_offers_${timestamp}_${randomId}`;
    return fileName;
  }
}
